<template>
  <v-container>
    <v-row
      align="center"
      justify="center"
    >
      <v-col cols="8">
        <v-form
          ref="form"
          v-model="formValid"
        >
          <BaseMasterFieldBlock
            title="Название"
            :horisontal="true"
          >
            <span
              slot="description"
            >Юридическое название компании без кавычек и формы собственности. Есть фирменное название? Отлично! Вводите его.</span>
            <template v-slot:input>
              <base-text-field
                v-model="program.name"
                :validate-on-blur="true"
                placeholder="Название компании"
                outlined
                counter
                minlength="1"
                maxlength="20"
                :rules="[
                  v => !!v || 'Название компании обязательно',
                  v => v.length <= 20 || 'Название компании не должно быть более 20 символов'
                ]"
              />
            </template>
          </BaseMasterFieldBlock>

          <BaseMasterFieldBlock
            title="Слоган"
          >
            <span slot="description">Напишите дополнительный текст, который позволит клиенту лучше понять чем ваша компания занимается, к чему стремится.</span>
            <template v-slot:input>
              <base-text-field
                v-model="program.slogan"
                :validate-on-blur="true"
                placeholder="Слоган компании"
                outlined
                counter
                maxlength="100"
                :rules="[
                  v => !v || v.length <= 100 || 'Название компании не должно быть более 20 символов'
                ]"
              />
            </template>
          </BaseMasterFieldBlock>

          <v-row>
            <v-col>
              <BaseMasterFieldBlock
                title="Карта клиента"
              >
                <div
                  slot="description"
                >
                  <div>Вот так будет выглядеть ваша карта в приложении.</div>
                  <div class="info-card-text">
                    <span>1. Измените цвет карты, кликнув на иконку</span>
                    <iconify-icon
                      class="info-card-text-icon"
                      icon="ion-color-fill-outline"
                      height="21"
                    />
                    <span>в шаблоне справа<span class="info-card-text-req">*</span></span>
                  </div>
                  <div class="info-card-text">
                    <span>2. Загрузите логотип, кликнув на иконку</span>
                    <iconify-icon
                      class="info-card-text-icon"
                      icon="feather-download"
                      height="21"
                    />
                    <span class="info-card-text-req">*</span>
                  </div>
                </div>
              </BaseMasterFieldBlock>
            </v-col>
            <v-col style="padding-top: 75px;">
              <company-card
                :program="program"
              />
            </v-col>
          </v-row>

          <BaseMasterFieldBlock
            title="Правила"
          >
            <span slot="description">Напишите правила программы программы лояльности.</span>
            <template v-slot:input>
              <v-textarea
                v-model="program.conditions"
                :placeholder="conditionsPlaceholder"
                rows="4"
                outlined
                no-resize
                readonly
                maxlength="20000"
                @click="()=>{
                  editConditions = program.conditions
                  editConditionsDialog = true
                }"
              />
            </template>
          </BaseMasterFieldBlock>

          <BaseMasterFieldBlock
            title="Выпуск карты"
          >
            <span
              slot="description"
            >Хотите ли вы дать вашим клиентам возможность выпускать карту самостоятельно в приложении? Если выключить параметр, то карту сможет выпустить только ваш сотрудник.</span>
            <template v-slot:input>
              <base-ext-switch
                v-model="program.allow_issue"
                label="Разрешить самостоятельный выпуск карты"
              />
            </template>
          </BaseMasterFieldBlock>
          <BaseMasterFieldBlock
            title="Прикрепление документов"
          >
            <span
              slot="description"
            >Хотите ли вы дать вашим клиентам возможность прикреплять документы к карте лояльности?</span>
            <template v-slot:input>
              <v-switch
                v-model="program.doc_attach_enabled"
                inset
                label="Разрешить прикрепление документов"
              />
            </template>
          </BaseMasterFieldBlock>
          <BaseMasterFieldBlock title="Выбор валюты">
            <span slot="description">Выберите валюту для отображения</span>
            <template v-slot:input>
              <v-autocomplete
                v-model="program.currency_id"
                class="model-select__shop"
                outlined
                clearable
                deletable-chips
                auto-select-first
                hide-selected
                :items="currency"
                item-value="id"
                item-text="name_sumbol"
                item-disabled="deleted_at"
                :rules="[
                        v => !!v || 'Выберите валюту'
                ]"
              />
            </template>
          </BaseMasterFieldBlock>

          <v-row>
            <v-col>
              <v-btn
                :disabled="!valid"
                color="primary"
                class="master-next-btn"
                @click="onNextClick"
              >
                Далее
                <v-icon right>
                  mdi-arrow-right
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <message-box
      v-model="editConditionsDialog"
      scrollable
      title="Правила программы лояльности"
      max-width="80%"
      type-mode="prompt"
      show-cancel-button
      @confirm="program.conditions = editConditions"
    >
      <v-textarea
        v-model="editConditions"
        :placeholder="conditionsPlaceholder"
        rows="20"
        :auto-grow="false"
        outlined
        clearable
        maxlength="20000"
        @focus="() => { if(!editConditions) editConditions = conditionsPlaceholder }"
      />
    </message-box>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { conditions } from '@/assets/json/program-conditions.js'

  export default {
    components: {
      CompanyCard: () => import('@/components/dialogs/CompanyCard'),
      MessageBox: () => import('@/components/message-box/MessageBox.vue'),
    },
    model: {
      prop: 'program',
      event: 'change',
    },
    props: {
      program: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        formValid: false,
        editConditionsDialog: false,
        editConditions: null,
      }
    },
    computed: {
      ...mapGetters({
        requisites: 'settings/organization/requisites',
        currency: 'company/program/currency'
      }),
      valid () {
        return this.formValid && this.program.logo
      },
      conditionsPlaceholder () {
        return conditions
          .replace('{program.name}', this.program.name)
          .replace('{merchant.org.name}', this.requisites.name || '{merchant.org.name}')
          .replace('{merchant.org.ogrn}', this.requisites.ogrn || '{merchant.org.ogrn}')
          .replace('{merchant.org.inn}', this.requisites.inn || '{merchant.org.inn}')
          .replace('{merchant.org.address}', this.requisites.address || '{merchant.org.address}')
          .trim()
      },
    },
    async created () {
      await this.getDetails()
      await this.getCurrency()
    },
    methods: {
      ...mapActions({
        getDetails: 'settings/organization/details',
        getCurrency: 'company/program/getCurrency'
      }),
      onNextClick () {
        if (this.$refs.form.validate()) {
          this.$emit('continue', true)
        }
      },
      onConditionsFocus () {
        console.log('onConditionsFocus')
        if (!this.program.conditions) {
          this.program.conditions = this.conditionsPlaceholder
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
@import 'master-style.scss';
@import '@/views/dashboard/form_component/_form-component.scss';
</style>
